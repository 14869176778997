<template>
  <div>
    <v-file-input
      v-model="model"
      :label="label"
      :prepend-icon="prependicon"
      :value="value"
      :disabled="disabled"
      :placeholder="message"
      :loading="loadingImage"
      :rules="rules"
      accept="image/jpg, image/jpeg, image/png"
      type="file"
      outlined
      dense
    >
    </v-file-input>
  </div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js'

export default {
  name: 'FileInput',
  props: {
    value: {
      type: null,
      default: () => null,
    },
    message: {
      type: String,
      default: () => 'File .jpg/.png, max. 2MB',
    },
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    loadingImage: {
      type: Boolean,
      default: () => false,
    },
    prependicon: {
      type: String,
      default: () => this.icon.mdiPaperclip,
    },
  },
  data() {
    return {
      icons: {
        mdiPaperclip,
      },
      rules: [value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!'],
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
</style>
